export const environment = {
  urlConfig: {
    serviceUrl: 'https://service.seoflurry.com/api/launchpad/v1',
    signalRBaseUrl: 'https://service.seoflurry.com/signalr',
    reportingUrl: 'https://reporting.boostability.com',
    myMarketInfoUrl: 'https://mymarketinfo.com',
    launchpadUrl: 'https://launchpad.boostability.com',
    yellowLogoutUrl: 'http://digital.sensis.com.au/okta/IdP_Logout.htm',
    restrictedReportingUrls: ['mymarketinfo.com', 'www.mymarketinfo.com'],
    frontAppUrl: 'https://app.frontapp.com'
  },
  testConfig: {
    testMessage: '',
    showTestMessage: false,
    logToConsole: false,
    usernamesChangedForTesting: false
  },
  defaultPartnerConfig: {
    id: 1,
    name: 'Boostability',
    logoLargeS3Url: 'https://s3.amazonaws.com/s3staticfiles/logos/1/03cfb792-397a-402f-912b-b6dc6977b8eb.png',
    logoSmallS3Url: 'https://s3.amazonaws.com/s3staticfiles/logos/1/cdea5b9a-2826-4156-96a8-b5bac7a681d1.png',
  },
  logToGoogleAnalytics: true,
  logToDevGoogleAnalytics: false,
  hotjarSiteId: 3544077,
  enableHotjar: true,
  disableAngularDebugChecks: true,
  jsBuildDate: 1731702685728
};
